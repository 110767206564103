import {
  FiClipboard,
  FiUsers,
  FiDollarSign,
  FiLink,
  FiFileText,
  FiCheckCircle,
  FiAlertCircle,
  FiXCircle,
  FiPlusCircle,
  FiThumbsUp,
  FiThumbsDown,
  FiLoader,
  FiCheck,
  FiClock,
  FiSmile,
  FiTrendingUp,
  FiZap,
  FiList,
  FiUserPlus,
  FiUserCheck,
  FiRadio,
  FiArchive,
  FiMoreHorizontal,
  FiBriefcase,
  FiCreditCard,
  FiPlus,
  FiInfo,
  FiSearch,
  FiFile,
  FiSend,
  FiCornerDownRight,
  FiCornerDownLeft,
  FiPercent,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

import { AsideGroup } from '@components/layouts/Aside/AsideGroup';
import { AsideItem } from '@components/layouts/Aside/AsideItem';

import { useAside } from '@hooks/aside';

export function RenderMenu(m: string): JSX.Element {
  const { handleAsideVisibility } = useAside();

  const handleLinkOnClick = useCallback(() => {
    handleAsideVisibility();

    const mainContent = document.getElementById('mainContent');

    if (mainContent) {
      window.scrollTo({
        top: mainContent.scrollHeight + 80,
        behavior: 'smooth',
      });
    }
  }, [handleAsideVisibility]);

  switch (m) {
    // ADMIN MENU

    case 'sharedFinancial':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Financeiro</h1>
            </div>

            <ul>
              <AsideItem
                link="/shared/financial/list"
                icon={FiList}
                title="Extrato"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'franchiseeECommerce':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>ECommerce</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo cadastro"
              link="/franchisees/ecommerce/new"
            />
          </AsideGroup>
        </>
      );

    case 'sellerECommerce':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>ECommerce</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo cadastro"
              link="/sellers/ecommerce/new"
            />
          </AsideGroup>
        </>
      );

    case 'adminTickets':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Tickets</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo ticket"
              link="/tickets/new"
            />
            <AsideItem
              icon={FiList}
              title="Todos os tickets solicitados"
              link="/tickets/list"
            />
            <AsideItem
              icon={FiSmile}
              title="Tickets feitos por mim"
              link="/tickets/me"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Departamentos</h1>
            </div>

            <AsideItem
              icon={FiBriefcase}
              title="Listar tickets por departamento"
              link="/tickets/departments/list"
            />
          </AsideGroup>
        </>
      );

    case 'adminBbankInvest':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Investimentos</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo investimento"
              link="/bbank-invest/investments/new"
            />

            <AsideItem
              icon={FiDollarSign}
              title="Ver investimentos"
              link="/bbank-invest/investments/list"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Percentuais de rendimentos</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo percentual de rendimento"
              link="/bbank-invest/investments/percentages/new"
            />

            <AsideItem
              icon={FiZap}
              title="Ver percentuais de rendimento"
              link="/bbank-invest/investments/percentages/list"
            />
          </AsideGroup>
        </>
      );

    case 'adminPagamentoDeContas':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Pagamento de contas</h1>
            </div>

            <ul>
              <AsideItem icon={FiDollarSign} title="CONTAS">
                <li>
                  <Link
                    to="/shared/bwallet/bill-payments/new-payment"
                    onClick={handleLinkOnClick}
                  >
                    Fazer novo pagamento
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/billets/bill-payments/my-payments"
                    onClick={handleLinkOnClick}
                  >
                    Ver meus pagamentos
                  </Link>
                </li>
              </AsideItem>
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminCreditCardLoan':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Empréstimo no cartão de crédito</h1>
            </div>

            <ul>
              <AsideItem icon={FiDollarSign} title="Empréstimo">
                <li>
                  <Link
                    to="/shared/load-on-credit-card/new"
                    onClick={handleLinkOnClick}
                  >
                    Fazer novo empréstimo
                  </Link>
                </li>

                <li>
                  <Link
                    to="/shared/load-on-credit-card/list"
                    onClick={handleLinkOnClick}
                  >
                    Ver todos os empréstimos
                  </Link>
                </li>
              </AsideItem>
            </ul>
          </AsideGroup>
        </>
      );

    case 'sharedCreditCardLoan':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Empréstimo no cartão de crédito</h1>
            </div>

            <ul>
              <AsideItem icon={FiDollarSign} title="Empréstimo">
                <li>
                  <Link
                    to="/shared/load-on-credit-card/new"
                    onClick={handleLinkOnClick}
                  >
                    Fazer novo empréstimo
                  </Link>
                </li>
              </AsideItem>
            </ul>
          </AsideGroup>
        </>
      );

    case 'departments':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Platformas</h1>
            </div>

            <AsideItem
              link="/platforms/list"
              title="Listar plataformas"
              icon={FiList}
            />

            <AsideItem
              link="/platforms/new"
              title="Nova plataforma"
              icon={FiPlusCircle}
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Departamentos</h1>
            </div>

            <AsideItem
              link="/departments/new"
              title="Novo departamento"
              icon={FiPlusCircle}
            />
            <AsideItem
              link="/departments/list"
              title="Listar departamentos"
              icon={FiList}
            />
          </AsideGroup>
        </>
      );

    case 'faq':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Faqs</h1>
            </div>

            <ul>
              <AsideItem link="/faq/all" icon={FiList} title="Todos" />
              <AsideItem
                link="/faq/new"
                icon={FiPlusCircle}
                title="Adicionar FAQ"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Categorias</h1>
            </div>

            <ul>
              <AsideItem
                link="/faq-categories/all"
                icon={FiList}
                title="Todas"
              />
              <AsideItem
                link="/faq-categories/new"
                icon={FiPlusCircle}
                title="Adicionar categoria"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminBbankPay':
      return (
        <>
          {/* <AsideGroup>
            <div>
              <h1>Estabelecimentos PJ</h1>
            </div>

            <ul>
              <AsideItem
                link="/bbank-pay/merchants/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=ativo"
                icon={FiCheckCircle}
                title="Ativos"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=pendente"
                icon={FiAlertCircle}
                title="Pendente"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=parado"
                icon={FiXCircle}
                title="Parados"
              />
              <AsideItem
                link="/bbank-pay/merchants/new"
                icon={FiBriefcase}
                title="Novo estabelecimento PJ"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Estabelecimentos PF</h1>
            </div>

            <ul>
              <AsideItem
                link="/bbank-pay/conveniences/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/bbank-pay/conveniences/status?s=ativo"
                icon={FiCheckCircle}
                title="Ativos"
              />
              <AsideItem
                link="/bbank-pay/conveniences/status?s=pendente"
                icon={FiAlertCircle}
                title="Pendente"
              />
              <AsideItem
                link="/bbank-pay/conveniences/status?s=parado"
                icon={FiXCircle}
                title="Parados"
              />
              <AsideItem
                link="/bbank-pay/conveniences/new"
                icon={FiUser}
                title="Novo estabelecimento PF"
              />
            </ul>
          </AsideGroup> */}

          <AsideGroup>
            <div>
              <h1>Estabelecimento TopBank PJ</h1>
            </div>

            <ul>
              <AsideItem
                link="/bbank-pay/top-bank/merchants/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=ativo"
                icon={FiCheckCircle}
                title="Ativos"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=pendente"
                icon={FiAlertCircle}
                title="Pendente"
              />
              <AsideItem
                link="/bbank-pay/merchants/status?s=parado"
                icon={FiXCircle}
                title="Parados"
              />
              <AsideItem
                link="/bbankpay/topbank/new"
                icon={FiBriefcase}
                title="Novo estabelecimento PJ"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Prospectos</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiThumbsUp}
                title="Aprovado"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiThumbsDown}
                title="Negado"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiLoader}
                title="Pendente"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>FAQ</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiClipboard}
                title="FAQ"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminCredcash':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>CredCash</h1>
            </div>

            <ul>
              <AsideItem link="/cred-cash/all" icon={FiUsers} title="Todos" />
              <AsideItem
                link="/cred-cash/credits?status=aprovado"
                icon={FiCheckCircle}
                title="Aprovados"
              />
              <AsideItem
                link="/cred-cash/credits?status=analise"
                icon={FiClock}
                title="Em análise"
              />
              <AsideItem
                link="/cred-cash/credits?status=negado"
                icon={FiXCircle}
                title="Negados"
              />
              <AsideItem
                link="/cred-cash/credits?status=pendente"
                icon={FiAlertCircle}
                title="Pendente"
              />
              <AsideItem
                link="/cred-cash/credits?status=pre-aprovado"
                icon={FiClock}
                title="Pré aprovado"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Novo</h1>
            </div>

            <ul>
              <AsideItem
                link="/cred-cash/new/pf"
                icon={FiPlusCircle}
                title="Pessoa física"
              />
              <AsideItem
                link="/cred-cash/new/pj"
                icon={FiPlusCircle}
                title="Pessoa jurídica"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financiamento de imóveis</h1>
            </div>

            <ul>
              <AsideItem
                link="/credcash/financing/property/list"
                icon={FiList}
                title="Ver todos os financiamentos solicitados"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Consignado privado</h1>
            </div>

            <ul>
              <AsideItem
                link="/cred-cash/consigned/prospects/all"
                icon={FiList}
                title="Ver prospectos"
              />
              <AsideItem
                link="/cred-cash/consigned/new"
                icon={FiPlus}
                title="Novo consignado"
              />
              <AsideItem
                link="/cred-cash/consigned/list"
                icon={FiList}
                title="Ver créditos consignados"
              />
              <AsideItem
                link="/cred-cash/consigned/companies/list"
                icon={FiList}
                title="Ver empresas"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Pesquisas BOTs</h1>
            </div>

            <ul>
              <AsideItem
                link="/bots/credits/person/list"
                icon={FiSmile}
                title="Pesquisas pessoa física"
              />
              <AsideItem
                link="/bots/credits/company/list"
                icon={FiBriefcase}
                title="Pesquisas pessoa jurídica"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sharedTransfer':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Transferência</h1>
            </div>

            <ul>
              <AsideItem
                link="/bwallet/transfer/new"
                icon={FiDollarSign}
                title="Transferência"
              />
              <AsideItem
                link="/shared/bwallet/transfer/list"
                icon={FiList}
                title="Ver transferências"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sharedDeposit':
      return (
        <AsideGroup>
          <div>
            <h1>Depositar</h1>
          </div>

          <ul>
            <AsideItem icon={FiDollarSign} title="Receber PIX">
              <li>
                <Link
                  to="/shared/bwallet/pix/qr-code/dynamic/new"
                  onClick={handleLinkOnClick}
                >
                  Enviar cobrança
                </Link>
              </li>
              <li>
                <Link
                  to="/shared/bwallet/pix/qr-code/static/new"
                  onClick={handleLinkOnClick}
                >
                  Receber
                </Link>
              </li>
            </AsideItem>
            <AsideItem
              link="/bwallet/billets/new"
              icon={FiFile}
              title="Boleto"
            />
            <AsideItem
              link="/page-in-development"
              icon={FiSend}
              title="TEC/DOC"
            />
            <AsideItem icon={FiFile} title="Depositar">
              <li>
                <Link
                  to="/shared/bwallet/pix/qr-code/dynamic/new"
                  onClick={handleLinkOnClick}
                >
                  Enviar cobrança
                </Link>
              </li>
              <li>
                <Link
                  to="/shared/bwallet/pix/qr-code/static/new"
                  onClick={handleLinkOnClick}
                >
                  Receber
                </Link>
              </li>
            </AsideItem>
          </ul>

          <div>
            <h1>Administradores</h1>
          </div>

          <ul>
            <AsideItem
              link="/bwallet/billets/all"
              icon={FiSearch}
              title="Ver boletos gerados"
            />
          </ul>
        </AsideGroup>
      );

    case 'sharedWithdrawalAndChange':
      return (
        <AsideGroup>
          <div>
            <h1>Saque/Troco</h1>
          </div>

          <ul>
            <AsideItem
              link="/bwallet/cardpayment/new"
              icon={FiCornerDownLeft}
              title="B2P"
            />
            <AsideItem
              link="/bwallet/operation/new"
              icon={FiCornerDownRight}
              title="P2P"
            />
          </ul>
        </AsideGroup>
      );

    // case 'adminBwallet':
    //   return <></>;

    case 'sharedInvest':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Investimentos</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo investimento"
              link="/shared/bbank-invest/investments/new"
            />

            <AsideItem
              icon={FiPercent}
              title="Ver planos de investimentos"
              link="/shared/bbank-invest/investments/percentages"
            />

            <AsideItem
              icon={FiDollarSign}
              title="Ver investimentos"
              link="/shared/bbank-invest/investments/list"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Status</h1>
            </div>

            <AsideItem
              icon={FiZap}
              title="Ativos"
              link="/shared/bbank-invest/investments/status?status=active"
            />

            <AsideItem
              icon={FiInfo}
              title="Pendentes"
              link="/shared/bbank-invest/investments/status?status=pending"
            />

            <AsideItem
              icon={FiXCircle}
              title="Cancelados"
              link="/shared/bbank-invest/investments/status?status=canceled"
            />

            <AsideItem
              icon={FiThumbsUp}
              title="Contratos enviados"
              link="/shared/bbank-invest/investments/status?status=contract_send"
            />
          </AsideGroup>
        </>
      );

    case 'franchiseBwallet':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Depositar</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bwallet/billets/new"
                icon={FiDollarSign}
                title="Novo boleto"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Boletos</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bwallet/billets/payed"
                icon={FiDollarSign}
                title="Ver boletos pagos"
              />
              <AsideItem
                link="/franchisees/bwallet/billets/emitted"
                icon={FiDollarSign}
                title="Ver boletos emitidos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerBwallet':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Depositar</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/bwallet/billets/new"
                icon={FiDollarSign}
                title="Novo boleto"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Boletos</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/bwallet/billets/payed"
                icon={FiDollarSign}
                title="Ver boletos pagos"
              />
              <AsideItem
                link="/sellers/bwallet/billets/emitted"
                icon={FiDollarSign}
                title="Ver boletos emitidos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerDeposit':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Depositar</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/bwallet/billets/new"
                icon={FiDollarSign}
                title="Novo boleto"
              />
            </ul>
          </AsideGroup>
        </>
      );

    // case 'sharedCharges':
    //   return (
    //     <>
    //       <AsideGroup>
    //         <div>
    //           <h1>Cobrança</h1>
    //         </div>

    //         <ul>
    //           <AsideItem
    //             link="/bwallet/billets/new"
    //             icon={FiFile}
    //             title="Boleto"
    //           />
    //           <AsideItem
    //             link="/bwallet/billets/all"
    //             icon={FiSearch}
    //             title="Ver boletos gerados"
    //           />
    //           <AsideItem
    //             link="/shared/bwallet/billets/assignors/me"
    //             icon={FiSearch}
    //             title="Boletos gerados por mim"
    //           />
    //           <AsideItem
    //             link="/shared/bwallet/billets/payers/me"
    //             icon={FiSearch}
    //             title="Boletos gerados para mim"
    //           />
    //         </ul>
    //       </AsideGroup>

    //       <AsideGroup>
    //         <div>
    //           <h1>PIX</h1>
    //         </div>

    //         <ul>
    //           <AsideItem icon={FiDollarSign} title="PIX">
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/pix/qr-code/dynamic/new"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Enviar cobrança
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/pix/qr-code/static/new"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Receber
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/pix/qr-code/dynamic/list"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Ver cobranças geradas
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/pix/qr-code/static/list"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Ver recebimentos gerados
    //               </Link>
    //             </li>
    //           </AsideItem>
    //         </ul>
    //       </AsideGroup>

    //       <AsideGroup>
    //         <div>
    //           <h1>Pagamento de contas</h1>
    //         </div>

    //         <ul>
    //           <AsideItem icon={FiDollarSign} title="CONTAS">
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/bill-payments/new-payment"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Fazer novo pagamento
    //               </Link>
    //             </li>
    //             <li>
    //               <Link
    //                 to="/shared/bwallet/billets/bill-payments/my-payments"
    //                 onClick={handleLinkOnClick}
    //               >
    //                 Ver meus pagamentos
    //               </Link>
    //             </li>
    //           </AsideItem>
    //         </ul>
    //       </AsideGroup>
    //     </>
    //   );

    case 'franquia':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Franqueados</h1>
            </div>

            <ul>
              <AsideItem link="/users/new" icon={FiPlusCircle} title="Novo" />
              <AsideItem link="/franchisees/all" icon={FiUsers} title="Todos" />
              <AsideItem
                link="/users/role?n=master"
                icon={FiUsers}
                title="Master"
              />
              <AsideItem
                link="/users/role?n=business"
                icon={FiUsers}
                title="Business"
              />
              <AsideItem
                link="/users/role?n=seller"
                icon={FiUsers}
                title="Promotores"
              />
              <AsideItem
                link="/franchisees/all"
                icon={FiUsers}
                title="Prospectos"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>FAQ</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiClipboard}
                title="FAQ"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'financeiro':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Financeiro</h1>
            </div>

            <ul>
              <AsideItem
                link="/financial/new"
                icon={FiDollarSign}
                title="Nova operação"
              />
              <AsideItem
                link="/financial/list"
                icon={FiList}
                title="Lista de pagamentos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminMarketing':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Categorias para marketing</h1>
            </div>

            <ul>
              <AsideItem
                link="/marketing/categories/new"
                icon={FiPlusCircle}
                title="Nova categoria"
              />
              <AsideItem
                link="/marketing/categories/list"
                icon={FiList}
                title="Lista de categorias"
              />
            </ul>

            <div>
              <h1>Arquivos para marketing</h1>
            </div>

            <ul>
              <AsideItem
                link="/marketing/categories/files"
                icon={FiList}
                title="Listar categorias para ver arquivos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminBoleto':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Boleto</h1>
            </div>

            <ul>
              <AsideItem
                link="/bwallet/billets/new"
                icon={FiFile}
                title="Novo Boleto"
              />
              <AsideItem
                link="/bwallet/billets/all"
                icon={FiSearch}
                title="Ver boletos gerados"
              />
              {/* <AsideItem
                link="/shared/bwallet/billets/assignors/me"
                icon={FiSearch}
                title="Boletos de cobrança"
              /> */}
              <AsideItem
                link="/shared/bwallet/billets/payers/me"
                icon={FiSearch}
                title="Boletos para pagamento"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminPix':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>PIX</h1>
            </div>

            <ul>
              <AsideItem icon={FiDollarSign} title="PIX">
                <li>
                  <Link
                    to="/shared/bwallet/pix/new-payment"
                    onClick={handleLinkOnClick}
                  >
                    Novo pagamento
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/pix/qr-code/dynamic/new"
                    onClick={handleLinkOnClick}
                  >
                    Meu PIX
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/pix/qr-code/static/new"
                    onClick={handleLinkOnClick}
                  >
                    Enviar cobrança
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/pix/qr-code/dynamic/list"
                    onClick={handleLinkOnClick}
                  >
                    Ver cobranças geradas
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/pix/qr-code/static/list"
                    onClick={handleLinkOnClick}
                  >
                    Ver recebimentos gerados
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shared/bwallet/transfer/list"
                    onClick={handleLinkOnClick}
                  >
                    Transações feitas
                  </Link>
                </li>
              </AsideItem>
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminLinks':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Links</h1>
            </div>

            <ul>
              <AsideItem
                link="/links/sites"
                icon={FiLink}
                title="Links de compartilhamento"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'relatorios':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Estabelecimentos</h1>
            </div>

            <ul>
              <AsideItem
                link="/reports/merchants"
                icon={FiCheckCircle}
                title="Situação"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financeiro</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiCheck}
                title="Disponível"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiClock}
                title="Último depósito"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiFileText}
                title="Histórico de depósito"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Desempenho</h1>
            </div>

            <ul>
              <AsideItem
                link="/reports/participations"
                icon={FiSmile}
                title="Participação"
              />
              <AsideItem
                link="/reports/production"
                icon={FiZap}
                title="Produção"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiTrendingUp}
                title="Evolução"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Códigos de ativação</h1>
            </div>

            <AsideItem
              link="/activation-codes"
              icon={FiMoreHorizontal}
              title="Todos"
            />
          </AsideGroup>
        </>
      );

    case 'users':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Usuários</h1>
            </div>

            <AsideItem title="Novo" link="/users/new" icon={FiUserPlus} />
            <AsideItem title="Todos" link="/users/all" icon={FiUsers} />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Saldos</h1>
            </div>

            <AsideItem
              title="Ver todos os saldos"
              link="/users/balances"
              icon={FiUserCheck}
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Mensalidades</h1>
            </div>

            <AsideItem
              title="Mensalidades"
              link="/monthly-payment"
              icon={FiUserCheck}
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Papeis</h1>
            </div>

            <AsideItem
              title="Administradores"
              link="/users/role?n=admin"
              icon={FiUserCheck}
            />

            <AsideItem
              title="Clientes"
              link="/users/role?n=client"
              icon={FiUserCheck}
            />

            <AsideItem
              title="Franqueados business"
              link="/users/role?n=business"
              icon={FiUserCheck}
            />

            <AsideItem
              title="Franqueados master"
              link="/users/role?n=master"
              icon={FiUserCheck}
            />

            <AsideItem
              title="Promotores"
              link="/users/role?n=seller"
              icon={FiUserCheck}
            />
          </AsideGroup>
        </>
      );

    case 'downloads':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Arquivos</h1>
            </div>

            {/* <AsideItem
              title="Arquivos de crédito"
              link="/credit/files"
              icon={FiArchive}
            /> */}

            <AsideItem
              title="Arquivos de usuários"
              link="/user/files"
              icon={FiArchive}
            />

            {/* <AsideItem
              title="Arquivos de estabelecimentos PJ"
              link="/merchants/files"
              icon={FiArchive}
            />

            <AsideItem
              title="Arquivos de estabelecimentos PF"
              link="/conveniences/files"
              icon={FiArchive}
            /> */}
          </AsideGroup>
        </>
      );

    case 'credCashDownloads':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>CreshCash</h1>
            </div>

            <AsideItem
              title="Arquivos de crédito"
              link="/credit/files"
              icon={FiArchive}
            />
          </AsideGroup>
        </>
      );

    case 'adminBbankConnect':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Novo</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Telemedicina"
              link="/bbank-connect/telemedicine/new"
            />

            <AsideItem
              icon={FiPlusCircle}
              title="SSP"
              link="/page-in-development"
            />

            <AsideItem
              icon={FiPlusCircle}
              title="SSV"
              link="/page-in-development"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Solicitações</h1>
            </div>

            <AsideItem
              icon={FiList}
              title="Telemedicina"
              link="/bbank-connect/telemedicine/all"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Pesquisa de mercado</h1>
            </div>

            <ul>
              <AsideItem
                title="Ver pesquisas feitas"
                icon={FiClipboard}
                link="/bbank-connect/researchs/list"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'prospects':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Prospectos</h1>
            </div>

            <AsideItem title="Todos" icon={FiList} link="/prospects/list" />
          </AsideGroup>
        </>
      );

    case 'news':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Notícias</h1>
            </div>

            <AsideItem
              title="Nova notícia"
              icon={FiPlusCircle}
              link="/news/create"
            />
            <AsideItem
              title="Listar notícias"
              icon={FiList}
              link="/news/list"
            />
          </AsideGroup>
          <AsideGroup>
            <div>
              <h1>Sliders</h1>
            </div>

            <AsideItem
              title="Nova imagem"
              icon={FiPlusCircle}
              link="/news/sliders/create"
            />
            <AsideItem
              title="Listar sliders"
              icon={FiList}
              link="/news/sliders/list"
            />
          </AsideGroup>
        </>
      );

    // CLIENT MENU

    case 'clientsSells':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Vendas</h1>
            </div>

            <ul>
              <AsideItem
                icon={FiDollarSign}
                title="Ver extrato de venda da maquininha"
                link="/shared/sells/pos-extract"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'clientCredcash':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Credcash</h1>
            </div>

            <ul>
              <AsideItem
                title="Acompanhar solicitações"
                link="/client/credits"
                icon={FiRadio}
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financiamento de imóveis</h1>
            </div>

            <ul>
              <AsideItem
                link="/client/cred-cash/financing/property/list"
                icon={FiDollarSign}
                title="Ver financiamentos solicitados"
              />

              <AsideItem
                title="Solicitar financiamento"
                icon={FiPlus}
                link="/client/cred-cash/financing/property/request"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'clientBbankConnect':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Bbank Connect</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Telemedicina"
              link="/client/bbank-connect/telemedicine"
            />
          </AsideGroup>
        </>
      );

    case 'clientFaq':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Categorias</h1>
            </div>

            <AsideItem
              icon={FiInfo}
              title="Ver categorias de perguntas frequentes"
              link="/faq-categories"
            />
          </AsideGroup>
        </>
      );

    case 'clientBbankPay':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Estabelecimentos</h1>
            </div>

            <ul>
              <AsideItem
                link="/client/bbank-pay/conveniences/list"
                icon={FiUsers}
                title="Todos os estabelecimentos PF"
              />

              <AsideItem
                link="/client/bbank-pay/merchants/all"
                icon={FiBriefcase}
                title="Todos os estabelecimentos PJ"
              />
            </ul>
          </AsideGroup>
        </>
      );

    // BUSINESS MENU

    case 'businessFaq':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Categorias</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Todas"
              link="/faq-categories"
            />
          </AsideGroup>
        </>
      );

    case 'businessCredcash':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>CredCash</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/cred-cash/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/franchisees/cred-cash/pending"
                icon={FiAlertCircle}
                title="Pendentes"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Novo</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/cred-cash/new/pf"
                icon={FiPlusCircle}
                title="Pessoa física"
              />
              <AsideItem
                link="/franchisees/cred-cash/new/pj"
                icon={FiPlusCircle}
                title="Pessoa jurídica"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financiamento de propriedade</h1>
            </div>

            <ul>
              <AsideItem
                title="Novo financiamento"
                link="/franchisees/cred-cash/financing/property/new"
                icon={FiPlusCircle}
              />

              <AsideItem
                title="Ver financiamentos"
                link="/franchisees/cred-cash/financing/property/list"
                icon={FiList}
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'businessClients':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Clientes</h1>
            </div>

            <AsideItem
              icon={FiUserPlus}
              title="Novo"
              link="/business/clients/new"
            />

            <AsideItem
              icon={FiUsers}
              title="Todos"
              link="/business/clients/all"
            />
          </AsideGroup>
        </>
      );

    case 'businessBbankConnect':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Bbank Connect</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo"
              link="/franchisees/bbank-connect/telemedicine/new"
            />

            <AsideItem
              icon={FiList}
              title="Solicitações"
              link="/franchisees/bbank-connect/telemedicine/all"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Solicitações</h1>
            </div>

            <AsideItem
              icon={FiList}
              title="Todas"
              link="/franchisees/bbank-connect/telemedicine/all"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Pesquisas de mercado</h1>
            </div>

            <AsideItem
              icon={FiList}
              title="Ver pesquisas feitas por mim"
              link="/franchisees/bbank-connect/researchs"
            />
            <AsideItem
              icon={FiList}
              title="Ver as pesquisas de mercado feitas pelos meus promotores"
              link="/franchisees/bbank-connect/researchs/sellers"
            />
          </AsideGroup>
        </>
      );

    case 'businessUploads':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Novo upload</h1>
            </div>

            <AsideItem
              title="Arquivos para CredCash"
              icon={FiArchive}
              link="/franchisees/uploads/cred-cash/"
            />
          </AsideGroup>
        </>
      );

    case 'businessBbankPay':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Estabelecimentos PJ</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bbank-pay/merchants/list"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/franchisees/bbank-pay/merchants/new"
                icon={FiPlusCircle}
                title="Novo"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Estabelecimentos PF</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bbank-pay/conveniences/list"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/franchisees/bbank-pay/conveniences/new"
                icon={FiPlusCircle}
                title="Novo"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Prospectos</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiThumbsUp}
                title="Aprovado"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiThumbsDown}
                title="Negado"
              />
              <AsideItem
                link="/page-in-development"
                icon={FiLoader}
                title="Pendente"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>FAQ</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiClipboard}
                title="FAQ"
              />
            </ul>
          </AsideGroup>
        </>
      );

    // MASTER MENU

    case 'masterCredcash':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>CredCash</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/cred-cash/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/franchisees/cred-cash/pending"
                icon={FiAlertCircle}
                title="Pendentes"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Novo</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/cred-cash/new/pf"
                icon={FiPlusCircle}
                title="Pessoa física"
              />
              <AsideItem
                link="/franchisees/cred-cash/new/pj"
                icon={FiPlusCircle}
                title="Pessoa jurídica"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financiamento de propriedade</h1>
            </div>

            <ul>
              <AsideItem
                title="Novo financiamento"
                link="/franchisees/cred-cash/financing/property/new"
                icon={FiPlusCircle}
              />

              <AsideItem
                title="Ver financiamentos"
                link="/franchisees/cred-cash/financing/property/list"
                icon={FiList}
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Consignado privado</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/cred-cash/consigned/prospects/new"
                icon={FiPlusCircle}
                title="Adicionar prospectos"
              />
              <AsideItem
                link="/franchisees/cred-cash/consigned/prospects/list"
                icon={FiList}
                title="Lista de prospectos"
              />
              <AsideItem
                link="/franchisees/cred-cash/consigned/new"
                icon={FiPlus}
                title="Novo crédito consignado"
              />
              <AsideItem
                link="/franchisees/cred-cash/consigned/list"
                icon={FiList}
                title="Ver créditos consignados"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'masterClients':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Clientes</h1>
            </div>

            <AsideItem
              icon={FiUserPlus}
              title="Novo"
              link="/master/clients/new"
            />

            <AsideItem
              icon={FiUsers}
              title="Todos"
              link="/master/clients/all"
            />
          </AsideGroup>
        </>
      );

    case 'masterBbankConnect':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Bbank Connect</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo"
              link="/franchisees/bbank-connect/telemedicine/new"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Solicitações</h1>
            </div>

            <AsideItem
              icon={FiList}
              title="Todas"
              link="/franchisees/bbank-connect/telemedicine/all"
            />
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Pesquisas de mercado</h1>
            </div>

            <AsideItem
              icon={FiList}
              title="Ver pesquisas feitas por mim"
              link="/franchisees/bbank-connect/researchs"
            />
            <AsideItem
              icon={FiList}
              title="Ver as pesquisas de mercado feitas pelos meus promotores"
              link="/franchisees/bbank-connect/researchs/sellers"
            />
          </AsideGroup>
        </>
      );

    case 'masterUploads':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Novo upload</h1>
            </div>

            <AsideItem
              title="Arquivos para CredCash"
              icon={FiArchive}
              link="/franchisees/uploads/cred-cash/"
            />
          </AsideGroup>
        </>
      );

    case 'masterBbankPay':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Estabelecimentos PJ</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bbank-pay/merchants/list"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/frachisees/bbank-pay/sellers?n=merchants"
                icon={FiSmile}
                title="Ver cadastros feitos por cada promotor"
              />
              <AsideItem
                link="/franchisees/bbank-pay/merchants/new"
                icon={FiPlusCircle}
                title="Novo"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Estabelecimentos PF</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/bbank-pay/conveniences/list"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/frachisees/bbank-pay/sellers?n=conveniences"
                icon={FiSmile}
                title="Ver cadastros feitos por cada promotor"
              />
              <AsideItem
                link="/franchisees/bbank-pay/conveniences/new"
                icon={FiPlusCircle}
                title="Novo"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>FAQ</h1>
            </div>

            <ul>
              <AsideItem
                link="/page-in-development"
                icon={FiClipboard}
                title="FAQ"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'masterFaq':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Categorias</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Todas"
              link="/faq-categories"
            />
          </AsideGroup>
        </>
      );

    case 'franchiseesLinks':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Links</h1>
            </div>

            <AsideItem
              icon={FiLink}
              title="Sites para indicação"
              link="/franchisees/links/sites"
            />
          </AsideGroup>
        </>
      );

    case 'sharedTickets':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Tickets</h1>
            </div>

            <AsideItem
              icon={FiPlusCircle}
              title="Novo ticket"
              link="/shared/tickets/new"
            />

            <AsideItem
              icon={FiList}
              title="Tickets que eu enviei"
              link="/shared/tickets/me"
            />
          </AsideGroup>
        </>
      );

    case 'franchiseesMarketing':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Arquivos para marketing</h1>
            </div>

            <ul>
              <AsideItem
                link="/franchisees/marketing/files/categories"
                icon={FiList}
                title="Ver categorias de arquivos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerProspects':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Prospectos</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/prospects/new"
                icon={FiPlusCircle}
                title="Novo prospecto"
              />
              <AsideItem
                link="/sellers/prospects/list"
                icon={FiList}
                title="Listar prospectos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerBbankConnect':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Pesquisa de mercado</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/bbank-connect/research/new"
                icon={FiPlusCircle}
                title="Nova pesquisa de mercado"
              />
              <AsideItem
                link="/sellers/bbank-connect/research/list"
                icon={FiClipboard}
                title="Ver pesquisas feitas por mim"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerClients':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Clientes</h1>
            </div>

            <ul>
              <AsideItem
                link="/shared/clients/new"
                icon={FiPlusCircle}
                title="Adicionar novo cliente"
              />

              <AsideItem
                link="/shared/clients/list"
                icon={FiList}
                title="Lista de clientes"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'franchiseesSellers':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Promotores</h1>
            </div>

            <ul>
              <AsideItem
                icon={FiPlusCircle}
                title="Adicionar Promotores"
                link="/franchisees/sellers/new"
              />
              <AsideItem
                icon={FiUsers}
                title="Ver todos os meus Promotores"
                link="/franchisees/sellers/all"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'franchiseesProspects':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Prospectos</h1>
            </div>

            <AsideItem
              title="Todos"
              icon={FiList}
              link="/franchisees/prospects/list"
            />
          </AsideGroup>
        </>
      );

    case 'sharedSells':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Vendas</h1>
            </div>

            <ul>
              <AsideItem
                icon={FiCreditCard}
                title="Ver extrato de venda da maquininha"
                link="/shared/sells/pos-extract"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerBbankPay':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Estabelecimentos PJ</h1>
            </div>

            <ul>
              <AsideItem
                icon={FiPlusCircle}
                title="Novo estabelecimento para pessoa jurídica"
                link="/sellers/bbank-pay/merchants/new"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Estabelecimentos PF</h1>
            </div>

            <ul>
              <AsideItem
                icon={FiPlusCircle}
                title="Novo estabelecimento para pessoa física"
                link="/sellers/bbank-pay/conveniences/new"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerLinks':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Links</h1>
            </div>

            <AsideItem
              icon={FiLink}
              title="Sites para indicação"
              link="/sellers/links/sites"
            />
          </AsideGroup>
        </>
      );

    case 'sellerMarketing':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>Arquivos para marketing</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/marketing/files/categories"
                icon={FiList}
                title="Ver categorias de arquivos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'sellerCredcash':
      return (
        <>
          <AsideGroup>
            <div>
              <h1>CredCash</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/cred-cash/all"
                icon={FiUsers}
                title="Todos"
              />
              <AsideItem
                link="/sellers/cred-cash/pending"
                icon={FiAlertCircle}
                title="Pendentes"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Novo</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/cred-cash/new/pf"
                icon={FiPlusCircle}
                title="Pessoa física"
              />
              <AsideItem
                link="/sellers/cred-cash/new/pj"
                icon={FiPlusCircle}
                title="Pessoa jurídica"
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Financiamento de propriedade</h1>
            </div>

            <ul>
              <AsideItem
                title="Novo financiamento"
                link="/sellers/cred-cash/financing/property/new"
                icon={FiPlusCircle}
              />

              <AsideItem
                title="Ver financiamentos"
                link="/sellers/cred-cash/financing/property/list"
                icon={FiList}
              />
            </ul>
          </AsideGroup>

          <AsideGroup>
            <div>
              <h1>Consignado privado</h1>
            </div>

            <ul>
              <AsideItem
                link="/sellers/cred-cash/consigned/prospects/new"
                icon={FiPlusCircle}
                title="Adicionar prospectos"
              />
              <AsideItem
                link="/sellers/cred-cash/consigned/prospects/list"
                icon={FiList}
                title="Lista de prospectos"
              />
            </ul>
          </AsideGroup>
        </>
      );

    case 'adminClientTaxes':
      return (
        <AsideGroup>
          <div>
            <h1>Taxas</h1>
          </div>

          <ul>
            <AsideItem link="/users/all" icon={FiUsers} title="Ver usuários" />
            <AsideItem
              link="/taxes-groups/list"
              icon={FiTrendingUp}
              title="Ver tabelas"
            />
          </ul>
        </AsideGroup>
      );

    default:
      return <></>;
  }
}
