import logo from '../assets/img/logo.svg';
import backgroundLogin from '../assets/img/bg-section01.png';
import contentLogin from '../assets/img/content.jpg';

export interface ITheme {
  colors: {
    primary: string;
    secondary: string;
  };
  images: {
    logo: string;
    banner: string;
    backgroundLogin: string;
    contentLogin: string;
  };
}

export const themeBbanking: ITheme = {
  colors: {
    primary: 'rgb(92, 41, 89)',
    secondary: 'rgb(120, 34, 115)',
  },
  images: {
    logo,
    banner: 'path/to/banner1.png',
    backgroundLogin,
    contentLogin,
  },
};

export const themePasstopay: ITheme = {
  colors: {
    primary: '#0e6ac7',
    secondary: '#c0504d',
  },
  images: {
    logo,
    banner: 'path/to/banner2.png',
    backgroundLogin,
    contentLogin,
  },
};
export const themeEdinheiroSmile: ITheme = {
  colors: {
    primary: '#017A11',
    secondary: '#FECC00',
  },
  images: {
    logo,
    banner: 'path/to/banner2.png',
    backgroundLogin,
    contentLogin,
  },
};

export type ThemeNames =
  | 'themeBbanking'
  | 'themePasstopay'
  | 'themeEdinheiroSmile';

const themes: Record<ThemeNames, ITheme> = {
  themeBbanking,
  themePasstopay,
  themeEdinheiroSmile,
};

export default themes;
